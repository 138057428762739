var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-layout", {
    ref: "formLayout",
    attrs: { column: _vm.optionChild.column, dataForm: _vm.dataObj },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }